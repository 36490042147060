/**
 * declared by the WebpackDefinePlugin
 */
declare const __ENVIRONMENT__: 'dev' | 'prod';

/**
 * declared by the WebpackDefinePlugin
 */
declare const __LAST_COMMIT_HASH__: string;

/**
 * declared by the WebpackDefinePlugin
 */
declare const __ENABLE_SENTRY_LOGGING__: boolean;

/**
 * declared by the WebpackDefinePlugin
 */
declare const __APP_SERVER_URL__: string | null;

/**
 * declared by the WebpackDefinePlugin
 */
declare const __PROD_SERVER_URL__: string;

/**
 * declared by the WebpackDefinePlugin
 */
declare const __STAGING_SERVER_URL__: string;

/**
 * declared by the WebpackDefinePlugin
 */
declare const __DISABLE_SIGN_UP__: boolean;

export const environment = {
  env: __ENVIRONMENT__,
  lastCommitHash: __LAST_COMMIT_HASH__,
  enableSentryLogging: __ENABLE_SENTRY_LOGGING__,
  appServerUrl: __APP_SERVER_URL__,
  prodServerUrl: __PROD_SERVER_URL__,
  stagingServerUrl: __STAGING_SERVER_URL__,
  disableSignUp: __DISABLE_SIGN_UP__
};
